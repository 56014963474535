<template>
  <div class="entry-form__list">
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.email.$model"
        :class="{
          error: $v.email.$error || sendError,
          valid: email && !$v.email.$error,
        }"
        class="input-entry"
        placeholder="E-mail"
        required
        type="email"
      />
      <div v-if="$v.email.$error" class="error-list__item">
        {{ $t("required_field") }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <vue-recaptcha
        ref="vueRecaptcha"
        :loadRecaptchaScript="true"
        :sitekey="capChaKey"
        class="pt-2 pb-4 d-flex justify-content-center w-100"
        size="normal"
        tabindex="0"
        theme="light"
        @expire="recaptchaExpired"
        @expired="expiredMethod"
        @fail="recaptchaFailed"
        @verify="recaptchaVerified"
      />
    </div>
    <div class="entry-form__item _submit">
      <button class="btn _w100" type="submit" @click="setPass">
        {{ $t("auth.send_new_pass") }}
      </button>
    </div>
    <div class="entry-form__item _privacy mb-0">
      <router-link to="/login">{{ $t("platforms.cancel") }}</router-link>
    </div>
    <notifications group="notifyAuth" />
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import AuthService from "@/api/serviceAuth";

export default {
  name: "Login",
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      email: "",
      recaptcha_response: "",
      sendError: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    capChaKey() {
      return "6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW";
    },
  },
  methods: {
    setPass() {
      this.$v.$touch();
      this.sendError = false;
      if (this.$v.$invalid) {
        this.$notify({
          group: "notifyAuth",
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
          ignoreDuplicates: true,
          type: "error",
        });
        return;
      }
      if (!this.recaptcha_response) {
        this.$notify({
          group: "notifyAuth",
          title: this.$t("sent_error"),
          text: this.$t("toast_error_captcha"),
          ignoreDuplicates: true,
          type: "error",
        });
        return;
      }
      const params = {
        email: this.email,
        recaptcha_response: this.recaptcha_response,
      };
      AuthService.restorePassword(params).then(
        (resp) => {
          if (resp.data.type === "Error") {
            this.$notify({
              group: "notifyAuth",
              title: this.$t("sent_error"),
              text: resp.data.message,
              ignoreDuplicates: true,
              type: "error",
            });
            this.sendError = true;
          } else {
            this.$notify({
              group: "notifyAuth",
              title: this.$t("sent_success"),
              text: this.$t("auth.send_link"),
              ignoreDuplicates: true,
              type: "success",
            });
            this.recaptchaFailed();
          }
        },
        (reason) => {
          console.log("error ======= ", reason);
        }
      );
    },
    expiredMethod() {
      this.recaptcha_response = "";
    },
    recaptchaVerified(response) {
      this.recaptcha_response = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.recaptcha_response = "";
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
      this.recaptcha_response = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-password .btn.btn-empty {
  padding: 0;
}
</style>